import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLeadAddComponent } from './defaultleadadd/defaultleadadd.component';
import { ExportExcelUserComponent } from './exportexceluser/exportexceluser.component';
import { CreateLeadComponent } from './feature/createlead/createlead.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { ExecutiveLeadsReportComponent } from './feature/executiveleadsreport/executiveleadsreport.component';
import { LeadGenerationComponent } from './feature/leadgeneration/leadgeneration.component';
import { SubmitIssueComponent } from './feature/submitissue/submitissue.component';
import { UserActivityComponent } from './feature/useractivity/useractivity.component';
import { UserManualPdfComponent } from './feature/usermanualpdf/usermanualpdf.component';
import { BranchMasterComponent } from './settings/branch/branchmaster/branchmaster.component';
import { MiscellaneousSettingComponent } from './settings/miscellaneous/miscellaneous/miscellaneous.component';
import { SettingComponent } from './settings/setting/setting.component';
import { UserSettingComponent } from './settings/user/user.component';
import { UserAddComponent } from './settings/useradd/useradd.component';
import { UserEditComponent } from './settings/useredit/useredit.component';



const routes: Routes = [
    //{ path: '', redirectTo:'/dashboard', pathMatch: 'full' },
  { path: '', redirectTo: '/createlead', pathMatch: 'full' },
  {
    path: 'dashboard', component: DashboardComponent, children: [
      { path: 'leadgeneration', component: LeadGenerationComponent }
      //{ path: 'allcontacts/:id', component: AllcontactsComponent }
    ]
    //path: 'dashboard', component: DashboardComponent
  },
  { path: 'createlead', component: CreateLeadComponent },
  { path: 'usermanualpdf', component: UserManualPdfComponent },
  { path: 'submitissue', component: SubmitIssueComponent },
  { path: 'exportexceluser', component: ExportExcelUserComponent },
  { path: 'defaultleadadd', component: DefaultLeadAddComponent },
  {
    path: 'setting', component: SettingComponent, children: [
      { path: 'user/:RandomId', component: UserSettingComponent},
      { path: 'useradd/:RandomId', component: UserAddComponent },
      { path: 'useredit/:RandomId', component: UserEditComponent },
      { path: 'branchmaster/:RandomId', component: BranchMasterComponent },
      { path: 'miscellaneous/:RandomId', component: MiscellaneousSettingComponent }
    ]
  },
  { path: 'executiveleadsreport', component: ExecutiveLeadsReportComponent },
  { path: 'useractivity', component: UserActivityComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
