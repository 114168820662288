import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA  } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { FiltersComponent } from './feature/filters/filters.component';
import { DashboardService } from './services/dashboard.service';
import { HeaderService } from './services/header.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Time24to12Format } from './feature/custompipe/time24to12.pipe';
import { MinutesToHours } from './feature/custompipe/MinuteToHour.pipe';
import { PhonePipe } from './feature/custompipe/Mobilenumber.pipe';
import { AppSettings } from './Appsettings';
/*import { MsalModule, MsalInterceptor } from '@azure/msal-angular';*/
import {
  DatepickerModule, WavesModule, SelectModule, SidenavModule, AccordionModule, MDBBootstrapModulesPro, MDBSpinningPreloader,
  TableModule, AutoCompleterModule, InputsModule, ModalModule, DropdownModule, ToastModule, ChartsModule, ChartSimpleModule,
  TooltipModule, ProgressbarModule, PopoverModule
} from 'ng-uikit-pro-standard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthendicationService } from './services/authendication.service';
import { GraphService } from './services/graph.service';
import { SearchFilterPipe } from './feature/custompipe/searchfilter.pipe';
import { RequestHeaderInterceptor } from './Interceptor/requestheader.interceptor';
import { HttpErrorInterceptor } from './Interceptor/httperror.interceptor';
import { ConfirmationdilogComponent } from './confirmationdilog/confirmationdilog.component';
import { ConfirmationdilogService } from './services/confirmationdilog.service';
import { SettingComponent } from './settings/setting/setting.component';
import { PasswordPipe } from './feature/custompipe/password.pipe';
import { AppComponentService } from './services/appcomponenet.service';
import { AuthGuardService } from './services/auth-guard.service';
import { SettingService } from './services/settings.service';
import { PageIndexService } from './services/pageindex.service';
import { LeadGenerationComponent } from './feature/leadgeneration/leadgeneration.component';
import { LeadGenerationService } from './services/leadgeneration.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateLeadComponent } from './feature/createlead/createlead.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AppRoutingModule } from './app-routing.module';
import { BranchMasterComponent } from './settings/branch/branchmaster/branchmaster.component';
import { UserSettingComponent } from './settings/user/user.component';
import { LeademailPopupComponent } from './feature/leademailpopup/leademailpopup.component';
import { DocsUploadDownloadService } from './services/docsuploaddownload.service';
import { MiscellaneousSettingComponent } from './settings/miscellaneous/miscellaneous/miscellaneous.component';
import { UserManualPdfComponent } from './feature/usermanualpdf/usermanualpdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SubmitIssueComponent } from './feature/submitissue/submitissue.component';
import { ExcelService } from './services/excel.service';
import { ExportExcelUserComponent } from './exportexceluser/exportexceluser.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BranchNamePopupComponent } from './settings/branch/branchnamepopup/branchnamepopup.component';
import { UserAddComponent } from './settings/useradd/useradd.component';
import { UserEditComponent } from './settings/useredit/useredit.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ManagerPopupComponent } from './shared/managerpopup/managerpopup.component';
import { EmailService } from './services/email.service';
import { UserAddPopupComponent } from './shared/useraddpopup/useraddpopup.component';
import { MiscellaneousPopupComponent } from './settings/miscellaneous/miscellaneouspopup/miscellaneouspopup.component';
import { MiscellaneousLevelPopupComponent } from './settings/miscellaneous/miscellaneouslevelpopup/miscellaneouslevelpopup.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { DefaultLeadAddComponent } from './defaultleadadd/defaultleadadd.component';
import { DefaultLeadAddService } from './services/defaultleadadd.service';
import { DynamicsService } from './services/dynamics.service';
import { UserActivityComponent } from './feature/useractivity/useractivity.component';
import { UserActivityService } from './services/useractivity.service';
import { ExecutiveLeadsReportComponent } from './feature/executiveleadsreport/executiveleadsreport.component';
import { ExecutiveLeadsReportService } from './services/executiveleads.service';

//export const protectedResourceMap: [string, string[]][] = [
//  [AppSettings.OAuthSettings.GRAPH_ENDPOINT, AppSettings.OAuthSettings.scopes]
//];
//console.log(AppSettings.OAuthSettings.scopes);
//const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [
    AppComponent, HeaderComponent, FooterComponent, DashboardComponent, 
    FiltersComponent, Time24to12Format, MinutesToHours, PhonePipe,
    SearchFilterPipe, SettingComponent, ConfirmationdilogComponent, PasswordPipe, LeadGenerationComponent, CreateLeadComponent,
    UserAddComponent, UserEditComponent, MiscellaneousSettingComponent, UserSettingComponent, LeademailPopupComponent,
    ExecutiveLeadsReportComponent, BranchMasterComponent, UserManualPdfComponent, SubmitIssueComponent, ExportExcelUserComponent,
    BranchNamePopupComponent, ManagerPopupComponent, UserAddPopupComponent, MiscellaneousPopupComponent, MiscellaneousLevelPopupComponent,
    DefaultLeadAddComponent, UserActivityComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule, FormsModule, ReactiveFormsModule, HttpClientModule, BrowserAnimationsModule, DatepickerModule, WavesModule, SelectModule, TableModule,
    MDBBootstrapModulesPro.forRoot(), ToastModule.forRoot(), NgSelectModule, DragDropModule, SidenavModule, AccordionModule,
    MDBBootstrapModulesPro.forRoot(), DropdownModule.forRoot(), AutoCompleterModule, InputsModule, ModalModule, ChartsModule, ChartSimpleModule,
    TooltipModule, PopoverModule, NgMultiSelectDropDownModule, AngularEditorModule, CommonModule, NgbModule, PdfViewerModule, NgxSpinnerModule,
    InfiniteScrollModule, AutocompleteLibModule,
    //MsalModule.forRoot({
    //  auth: {
    //    clientId: AppSettings.OAuthSettings.clientId,
    //    //authority: 'https://login.microsoftonline.com/common/',
    //    authority: AppSettings.OAuthSettings.authority,
    //    validateAuthority: true,
    //    redirectUri: AppSettings.OAuthSettings.redirectUri,
    //    postLogoutRedirectUri: AppSettings.OAuthSettings.redirectUri,
    //    navigateToLoginRequestUrl: true
    //  },
    //  cache: {
    //    cacheLocation: 'localStorage',
    //    storeAuthStateInCookie: isIE, // set to true for IE 11
    //  }
    //},
    //  {
    //    popUp: !isIE,
    //    consentScopes: [
    //      'user.read',
    //      'openid',
    //      'offline_access',
    //      'profile',
    //      AppSettings.OAuthSettings.api
    //    ],
    //    unprotectedResources: ['https://www.microsoft.com/en-us/'],
    //    protectedResourceMap: new Map([[AppSettings.OAuthSettings.GRAPH_ENDPOINT, [AppSettings.OAuthSettings.api]]]),
    //    extraQueryParameters: {}
    //  })
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [DashboardService, HeaderService, MDBSpinningPreloader, AppComponentService, AuthGuardService, SettingService,
    DashboardComponent, DatePipe, AuthendicationService, GraphService, ConfirmationdilogService, PageIndexService,
    LeadGenerationService, ProgressbarModule, ExecutiveLeadsReportService, DocsUploadDownloadService, ExcelService, EmailService, DefaultLeadAddService,
    DynamicsService, UserActivityService,
      /*{provide: HTTP_INTERCEPTORS,useClass: MsalInterceptor,multi: true},*/
      { provide: HTTP_INTERCEPTORS, useClass: RequestHeaderInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
    bootstrap: [AppComponent]
  , entryComponents: [ConfirmationdilogComponent, CreateLeadComponent, LeademailPopupComponent, BranchNamePopupComponent, ManagerPopupComponent, UserAddPopupComponent,
    MiscellaneousPopupComponent, MiscellaneousLevelPopupComponent]
})
export class AppModule { }
