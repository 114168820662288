import { Component, OnInit, HostListener, ViewChild, HostBinding} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponentService } from 'src/app/services/appcomponenet.service';
import { AppSettings } from 'src/app/Appsettings';
import * as $ from 'jquery';
import {MdbTableDirective, ToastService } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { HeaderService } from '../../services/header.service';
import { CryptoService } from '../../services/crypto.service';
import { DocsUploadDownloadService } from '../../services/docsuploaddownload.service';
/*import { GraphService } from '../../services/graph.service';*/
import { NewEvent } from '../../Interface/new-event';
import { HttpEventType } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { EmailService } from '../../services/email.service';
import { ExcelService } from '../../services/excel.service';
import { SearchedField, SearchType } from '../../Interface/user';
import { UserActivityService } from '../../services/useractivity.service';
import { ExecutiveLeadsReportService } from '../../services/executiveleads.service';

@Component({
  selector: 'app-useractivity',
  templateUrl: './useractivity.component.html',
  styleUrls: ['./useractivity.component.scss']
})
export class UserActivityComponent implements OnInit {
  @HostBinding('class.app-exportLead') someField: boolean = true;
  Username: any; FullnameValue: any;EmailidValue: any;authorizeUserDetails: any[];jobno: any;
 
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };

  fromdate: any = ''; todate: any = ''; selectdate: any;exportUsers: any[];

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  searchText: any; previous: string; isDesc: boolean; column: any; activeBranchContact: boolean;
  downloadExcel: boolean; activeExportUsersdata: boolean = false;
  fileToUpload: File = null; base64textString: any; OutlookemailList: any[]; model = new NewEvent(); FromDate: any; ToDate: any; ExecutiveEmail: any[];
  ccemails: any; Emails: any[]; previousmonth: boolean; EmailBody: any; EmailBodyArray: any[];


  FetchTableSearchList: any[]; loadGifTableSearch: boolean; keywordTableSearch = 'searchoption'; historyHeadingTableSearch: any; searchTextFlag: any='0';
  constructor(private headerService: HeaderService, private appComponentService: AppComponentService, private router: Router, private activatedroute: ActivatedRoute,
    public cryptoservice: CryptoService, private userActivityService: UserActivityService, public datepipe: DatePipe, private docsUploadDownloadService: DocsUploadDownloadService,
    /*private graphService: GraphService,*/ private toastrService: ToastService, private spinner: NgxSpinnerService, private emailservice: EmailService,
    private excelService: ExcelService, private executiveLeadsReportService: ExecutiveLeadsReportService) { }

  ngOnInit() {
    debugger
    this.getAuthorizeUserDetails();
  }

  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      var Fullname = this.authorizeUserDetails[0]['fullname'];
      var UserId = this.authorizeUserDetails[0]['userid'];
      var BranchName = this.authorizeUserDetails[0]['branchname'];
      var Level = this.authorizeUserDetails[0]['level'];
      var Emailid = this.authorizeUserDetails[0]['email'];
      var Application = this.authorizeUserDetails[0]['application'];
      var Password = this.authorizeUserDetails[0]['password'];
      if (UserId != "") {
        this.Username = UserId;
      }
      if (Fullname != "") {
        this.FullnameValue = Fullname;
      }
      if (Emailid != "") {
        this.EmailidValue = Emailid;
      }

      this.loadExecutiveEmails();
      this.previousMonthChange(true);
    }
  }
  loadExecutiveEmails() {
    let strusername = this.Username;
    this.userActivityService.getExecutiveEmail(strusername).subscribe(getresult => { this.getExecutiveEmailSuccess(getresult) },
      res => { this.onError(res) });
  }
  getExecutiveEmailSuccess(getresult: any[]) {
    this.ExecutiveEmail = [];
    this.Emails = [];
    if (getresult.length > 0) {
      this.ExecutiveEmail = getresult;
      for (var i = 0; i < this.ExecutiveEmail.length; i++) {
        this.Emails.push(this.ExecutiveEmail[i].email)
      }
    }
  }
  viewResult() {
    debugger
    this.clearSearchForViewResult();
    this.spinner.show();
    let strusername = this.Username;
    let list = [];
      let Item = {
        "userid": strusername,
        "fromdate": this.fromdate?this.fromdate.toString():'',
        "todate": this.todate?this.todate.toString():''
      };
    list.push(Item);
    if (list) {
      this.FromDate = list[0].fromdate;
      this.ToDate = list[0].todate;
    }
    this.userActivityService.getUserActivity(list).subscribe(getresult => { this.getUserActivitySuccess(getresult) },
          res => { this.onError(res) });
  }
  //exportExcelData() {
  //  //let radioChangeval = this.radioChangeval;
  //  //let selectyear = this.selectyear;
  //  let lowercaserUsername = this.Username.toLowerCase();
  //  if (radioChangeval == 'Company Analysis') {
  //    if (lowercaserUsername == 'manager2') {
  //      this.selectyear = '';
  //      window.open(AppSettings.OAuthSettings.URIPrint + 'Q29tcGFueWFuYWx5c2lzZmxhZw=' + window.btoa(radioChangeval)
  //        + '&Selectyear=' + window.btoa(selectyear)
  //        + '&UserName=' + window.btoa(this.Username), 'null', 'left=350,top=250,height=640,width=840,status=yes,toolbar=no,menubar=yes,location=no,resizable=no,location=no');
  //    }
  //  }
  //  else {
  //    if (lowercaserUsername == 'manager2') {
  //      this.selectyear = '';
  //      window.open(AppSettings.OAuthSettings.URIPrint + 'QnJhbmNoYW5hbHlzaXNmbGFn=' + window.btoa(radioChangeval)
  //        + '&Selectyear=' + window.btoa(selectyear)
  //        + '&UserName=' + window.btoa(this.Username), 'null', 'left=350,top=250,height=640,width=840,status=yes,toolbar=no,menubar=yes,location=no,resizable=no,location=no');
  //    }
  //  }
  //}
  exportExcelData() {
    /*this.downloadExcel = true;*/
    this.viewResult();
   /* this.downloadUsersExcel();*/
    const downloadWindow = window.open(AppSettings.OAuthSettings.URIPrint + 'RXhwb3J0TGVhZHM=' + window.btoa('useractivity')
      + '&FromDate=' + window.btoa(this.fromdate) + '&ToDate=' + window.btoa(this.todate)
      + '&UserName=' + window.btoa(this.Username) + '&Flag=' + window.btoa("download"),
      'null', 'left=350,top=250,height=640,width=840,status=yes,toolbar=no,menubar=yes,location=no,resizable=no,location=no');

    // Periodically check if the download has started (or the window is closed)
    const interval = setInterval(() => {
      downloadWindow.close();
      // Check if the window is still open
      if (downloadWindow && downloadWindow.closed) {
        // Stop the interval
        clearInterval(interval);

        // Perform any other action if needed
        this.toastrService.success('Report download completed', '', this.options);
      }
    }, 20000); // Check every 8 second
  }
  emailUserstoExecutive() {
    this.viewResult();
    this.downloadExcel = true;
    this.FromDate = this.fromdate;
    this.ToDate = this.todate;
    window.open(AppSettings.OAuthSettings.URIPrint + 'RXhwb3J0TGVhZHM=' + window.btoa('useractivity')
      + '&FromDate=' + window.btoa(this.fromdate) + '&ToDate=' + window.btoa(this.todate)
          + '&UserName=' + window.btoa(this.Username) + '&Flag=' + window.btoa("ftp"), '_blank', 'noopener noreferrer');

    setTimeout(() => {
      if (this.downloadExcel == true) {
        this.sentEmailUsersExcel();
      }
    }, 10000);
  }
  //downloadFile(data: any) {
  //  debugger
  //  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  //  const header = Object.keys(data[0]);
  //  const header1 = ['Full Name', 'Company', 'Site Address', 'Contact Name', 'Phone #', 'Email', 'City', 'State', 'Zip', 'Date', 'Office',
  //    'Lead Status', 'Existing Acount', 'Line of Business', 'Lead Category', 'Job Title', 'Note', 'Created By','Created Date']
  //  const csv = data.map((row) =>
  //    header
  //      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
  //      .join(',')
  //  );
  //  csv.unshift(header.join(','));
  //  const csvArray = csv.join('\r\n');

  //  const a = document.createElement('a');
  //  const blob = new Blob([csvArray], { type: 'text/csv' });
  //  const url = window.URL.createObjectURL(blob);

  //  a.href = url;
  //  let filename = this.Username + '(' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + ')' + ' ' + 'Leads.csv';
  //  a.download = filename;
  //  //'myFile.csv';
  //  a.click();
  //  window.URL.revokeObjectURL(url);
  //  a.remove();
  //  const formData = new FormData();
  //  formData.append('file', blob, filename);
  //  //this.docsUploadDownloadService.uploadDocument(formData).subscribe(getresult => { this.uploadDocumentSuccess(getresult) },
  //  //  res => { this.onError(res) });
  //  var object = {};
  //  formData.forEach((value, key) => object[key] = value);
  //  var json = JSON.stringify(object);
  //  this.handleFileInput(object);



  //  //let subject = 'Test';
  //  //let body = 'Hi';
  //  //let to = 'parajakec04@gmail.com';
  //  //let cc = '';
  //  //let attachment = this.fileToUpload;
  //  //let attachmentbyte = this.base64textString;
  //  //let emailgraphEvent = this.model.getEmailGraphEvent(subject, body, to, cc, attachment, attachmentbyte);
  //  //console.log(emailgraphEvent);
  //  //this.OutlookemailList = [];
  //  //this.graphService.addEventToEmail(emailgraphEvent).then((events) => {
  //  //  this.OutlookemailList.push(events);
  //  //  this.emailSent();
  //  //  return true;
  //  //});

  //  //let filePathval = this.Username + '(' + this.datepipe.transform(new Date(), 'MM-dd-yyyy') + ')' + '-' + 'ExportLeads.xls';
  //  //this.exportLeadService.readDocument(filePathval).subscribe(
  //  //  data => {
  //  //    debugger
  //  //    switch (data.type) {
  //  //      case HttpEventType.Response:
  //  //        //this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
  //  //        const downloadedFile = new Blob([data.body], { type: data.body.type });
  //  //        formData.append('file', downloadedFile, filename);
  //  //        var object = {};
  //  //        formData.forEach((value, key) => object[key] = value);
  //  //        var json = JSON.stringify(object);
  //  //        this.handleFileInput(object);
  //  //    }
        
  //  //  }
  //  //);

  //  this.downloadExcel = false;
  //}
  //uploadDocumentSuccess(getresult: any) {
  //  let documentresult = getresult;
  //}

  getUserActivitySuccess(getresult: any[]) {
    this.spinner.hide();
    this.activeExportUsersdata = true;
    this.exportUsers = [];
    if (getresult.length > 0) {
      this.exportUsers = getresult;
      this.mdbTable.setDataSource(this.exportUsers);

      this.exportUsers = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();


      //if (this.downloadExcel == true) {
      //  let filterExportLeads = this.exportLeads.map(function (obj) {
      //    return {
      //      userfullname: obj.userfullname,
      //      managerfullname: obj.managerfullname,
      //      company: obj.company,
      //      siteaddress: obj.siteaddress,
      //      customercontactname: obj.customercontactname,
      //      phonenumber: obj.phonenumber,
      //      email: obj.email,
      //      city: obj.city,
      //      state: obj.state,
      //      zip: obj.zip,
      //      date: obj.date,
      //      office: obj.office,
      //      leadstatus: obj.leadstatus,
      //      existingacount: obj.existingacount,
      //      lineofbusiness: obj.lineofbusiness,
      //      leadcategory: obj.leadcategory,
      //      jobtitle: obj.jobtitle,
      //      previousstatus: obj.previousstatus,
      //      note: obj.note,
      //      createdby: obj.createdby,
      //      createddate: obj.createddate
      //    }
      //  });
      //  if (filterExportLeads) {
      //    this.downloadFile(filterExportLeads);
      //  }
      //}      
    }
  }
  downloadUsersExcel() {
    this.downloadExcel == false;
      //let filePathval = this.Username + '(' + this.datepipe.transform(new Date(), 'MM-dd-yyyy') + ')' + '-' + 'UserActivity.xls';
    let filePathval = `${'UserActivity'} ${this.fromdate} through ${this.todate} (${this.Username}).${'xlsx'}`;
    this.userActivityService.downloadDoc(filePathval).subscribe(
        data => {
          debugger
          switch (data.type) {
            case HttpEventType.Response:
              //this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
              const downloadedFile = new Blob([data.body], { type: data.body.type });
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');
              document.body.appendChild(a);
              a.download = filePathval;
              a.href = URL.createObjectURL(downloadedFile);
              a.target = '_blank';
              a.click();
              document.body.removeChild(a);
              break;
          }

        }); 
  }
  sentEmailUsersExcel() {
    const formData = new FormData();
    let filePathval = `${'UserActivity'} ${this.fromdate} through ${this.todate} (${this.Username}).${'xlsx'}`;
    //let filePathval = this.Username + '(' + this.datepipe.transform(new Date(), 'MM-dd-yyyy') + ')' + '-' + 'UserActivity.xls';
    this.userActivityService.downloadDoc(filePathval).subscribe(
      data => {
        debugger
        switch (data.type) {
          case HttpEventType.Response:
            //this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            formData.append('file', downloadedFile, filePathval);
            var object = {};
            formData.forEach((value, key) => object[key] = value);
            var json = JSON.stringify(object);
            this.handleFileInput(object);
        }

      });
  }
  //downloadLeadstoExecutive() {
    //this.viewResult();
  //  this.downloadExcel = true;
  //  this.FromDate = this.fromdate;
  //  this.ToDate = this.todate;
  //  window.open(AppSettings.OAuthSettings.URIPrint + 'RXhwb3J0TGVhZHM=' + window.btoa('exportleads')
  //    + '&FromDate=' + window.btoa(this.fromdate) + '&ToDate=' + window.btoa(this.todate)
  //    + '&UserName=' + window.btoa(this.Username), '_blank', 'noopener noreferrer');

  //  setTimeout(() => {
  //    if (this.downloadExcel == true) {
  //      this.downloadExecutiveLeadsExcel();
  //    }
  //  }, 7000);
  //}
  changeDate() {
    this.exportUsers = []; let from; let to;
    this.activeExportUsersdata = false;
    this.previousmonth = false;
    if (this.fromdate) {
      from = new Date(this.fromdate);
    }
    if (this.todate) {
      to = new Date(this.todate);
    }

    if (from && to) {
      if (to < from) {
        this.toastrService.warning('To date cannot be earlier than From date!', '', this.options);
        this.todate = null;
        this.todate = '';
      }
      else {
        this.viewResult();
      }
    }
  }
  handleFileInput(evt: any) {
    debugger
    const file = evt.file;
    this.fileToUpload = file;
    if (file) {
      const reader = new FileReader();
     
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(readerEvt) {
    debugger
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    console.log(this.base64textString);

    this.formatEmailBody();
  }
  formatEmailBody() {
    this.EmailBodyArray = []; let leadcount = ''; let company = ''; let userfullname = '';
    let managercompany = ''; let Emailbody = ''; let previouscompany = ''; let managerfullname = '';
    for (var i = 0; i < this.exportUsers.length; i++) {
      company = this.exportUsers[i].usercompany;
      managerfullname = this.exportUsers[i].managerfullname;
      if (i == 0) {
        Emailbody = '<p> Company:' + ' ' + '<b>' + company + '</b></p><br/>';
        Emailbody = Emailbody + 'Manager:' + ' ' + '-' + ' ' + '<b>' + managerfullname + '</b>' + '<br/> ';
        for (var j = 0; j < this.exportUsers.length; j++) {
          managercompany = this.exportUsers[j].usercompany;
          if (company == managercompany) {
            userfullname = this.exportUsers[j].userfullname;
            leadcount = this.exportUsers[j].leadcount;
            Emailbody = Emailbody + 'User Name:' + ' ' + '-' + ' ' + '<b>' + userfullname + '</b>' + ' ' + '-' + ' ' + '<b>' + leadcount + '</b>';
          }
        }
        this.EmailBodyArray.push(Emailbody);
        //if (previouscompany == company) {
        //  Emailbody = Emailbody + 'Lead Status:' + '<br/> ';
        //  Emailbody = Emailbody + '<b>' + leadstatus + '</b>' + ' ' + '-' + ' ' + '<b>' + ststuscount + '</b>';
        //}

        previouscompany = company;
      }
      else if (i != 0 && previouscompany != '') {
        if (company != previouscompany) {
          Emailbody = '<p> Company:' + ' ' + '<b>' + company + '</b></p><br/>';
          Emailbody = Emailbody + 'Manager:' + ' ' + '-' + ' ' + '<b>' + managerfullname + '</b>' + '<br/> ';
          for (var j = 0; j < this.exportUsers.length; j++) {
            managercompany = this.exportUsers[j].usercompany;
            if (company == managercompany) {
              userfullname = this.exportUsers[j].userfullname;
              leadcount = this.exportUsers[j].leadcount;
              Emailbody = Emailbody + 'User Name:' + ' ' + '-' + ' ' + '<b>' + userfullname + '</b>' + ' ' + '-' + ' ' + '<b>' + leadcount + '</b>';
            }
          }
          this.EmailBodyArray.push(Emailbody);
          //if (previouscompany == company) {
          //  Emailbody = Emailbody + 'Lead Status:' + '<br/> ';
          //  Emailbody = Emailbody + '<b>' + leadstatus + '</b>' + ' ' + '-' + ' ' + '<b>' + ststuscount + '</b>';
          //}
        }
        previouscompany = company;
      }

    }
    if (this.EmailBodyArray.length > 0) {
      this.EmailBody = this.EmailBodyArray.toString();
    }
    this.loadEmailDetails();
  }
  loadEmailDetails() {
    debugger
    let body = '';
    let attachment = this.fileToUpload;
    let attachmentbyte = this.base64textString;
    if (attachment && attachmentbyte && this.Emails.length > 0) {
      let subject = 'Pavion Users Activity' + ' ' + '(sent by ' + this.EmailidValue + ')';
      if (this.EmailBody !='') {
        body = '<p>Please see attached list of users added in the User Master from' + ' ' +
          '<b>' + this.FromDate + '</b>' + ' to ' + ' ' + '<b>' + this.ToDate + '</b>' + '<br/><br/>' +
          this.EmailBody + '</p>';
      }
      let to = this.Emails.toString();
      let toname = to.substring(0, to.indexOf('@'));
      let cc = '';
      let emailgraphEvent = this.model.getEmailGraphEvent(subject, body, to, cc, attachment, attachmentbyte);
      console.log(emailgraphEvent);
      this.OutlookemailList = [];
      //this.graphService.addEventToEmail(emailgraphEvent).then((events) => {
      //  this.OutlookemailList.push(events);
      //  this.emailSent();
      //  return true;
      //});
      const formData = new FormData();
      if (attachment != null) {
        formData.append('cc', cc ? cc : '');
        formData.append('attachments', attachment ? attachment : null, attachment.name ? attachment.name : null);
        formData.append('subject', subject);
        formData.append('body', body);
        formData.append('to', to);
        formData.append('toname', toname);
      }
      else {
        formData.append('cc', cc ? cc : '');
        formData.append('subject', subject);
        formData.append('body', body);
        formData.append('to', to);
        formData.append('toname', toname);
      }
      this.emailservice.sendEmailAttachment(formData).subscribe(getresult => { this.sendEmailSuccess(getresult) },
        res => { this.onError(res) });
      this.FromDate = ''; this.ToDate = ''; this.EmailBodyArray = [];
    }
    else {
      this.toastrService.warning('No user has been assigned the executive level so users could not be sent. Please contact administrator.', '', this.options);
    }
  }
  sendEmailSuccess(getresult) {
    if (getresult != '') {
      let emailmsg = getresult;
      if (emailmsg == '1') {
        this.toastrService.success('Email sent to executive', '', this.options);
      }
      else {
        this.toastrService.error('Email could not be sent due to network error!', '', this.options);
      }

    }
    this.downloadExcel == false;
  }
  previousMonthChange(evt) {
    debugger
    let previousmonth;
    if (evt) {
      if (evt == true || evt.target.checked) {
        previousmonth = true;
      }
      else {
        previousmonth = false;
      }
    }
    if (previousmonth == true) {
      this.exportUsers = [];
      this.activeExportUsersdata = false;
      this.previousmonth = true;
      var date = new Date();
      let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 2);
      let finalFirstDay = firstDay.setUTCHours(0, 0, 0, 0);
      let lastDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let finalLastDay = lastDay.setUTCHours(0, 0, 0, 0);
      console.log(firstDay.toISOString(), lastDay.toISOString())
      this.fromdate = this.datepipe.transform(new Date(finalFirstDay), 'yyyy-MM-dd');
      this.todate = this.datepipe.transform(new Date(finalLastDay), 'yyyy-MM-dd');
    }
    else {
      this.fromdate = '';
      this.todate = '';
      this.changeDate();
    }
  }
  //emailSent() {
  //  if (this.OutlookemailList != undefined) {
  //    if (this.OutlookemailList.length > 0) {
  //      for (var i = 0; i < this.OutlookemailList.length; i++) {
  //        let subject = this.OutlookemailList[i].subject;
  //        //var from = this.OutlookemailList[i].from.emailAddress.address;
  //        console.log(subject);
  //        if (subject != '') {
  //          var id = this.OutlookemailList[i].id;
  //          var parentFolderId = this.OutlookemailList[i].parentFolderId;
  //          var outlookemailid = id;
  //          this.graphService.SendEmail(outlookemailid);
  //          console.log(id);
  //        }
  //      }
  //    }
  //  }
  //  this.toastrService.success('Email sent to executive', '', this.options);
  //  this.downloadExcel == false;
  //}

  getFetchTableSearchListSuccess(getresult: any[]) {
    debugger
    this.loadGifTableSearch = false;
    this.FetchTableSearchList = [];
    if (getresult.length > 0) {
      this.FetchTableSearchList = getresult;
    }
  }
  getSelectedSearchText(evt) {
    if (evt) {
      this.searchTextFlag = '1';
    }
  }
  clearSearch() {
    debugger
    this.searchText = '';
    this.spinner.hide();
    this.viewResult();
  }
  clearSearchForViewResult() {
    this.searchText = '';
    this.spinner.hide();
  }
  exportExcelUserGrid() {
    debugger
    this.viewResult();
    setTimeout(() => {
      if (this.exportUsers.length > 0) {
        this.spinner.show();
        let filterExportLeads = this.exportUsers.map(function (obj) {
          return {
            usercompany: obj.usercompany,
            userfullname: obj.userfullname,
            userid: obj.userid,
            managerfullname: obj.managerfullname,
            email: obj.email,
            leadcount: obj.leadcount
          }
        });
        if (filterExportLeads) {
          //this.downloadFile(filterExportLeads);
          let excelfilename = 'User Activity';
          let excelHeaders = ['Company', 'User Name', 'User Id', 'Manager Name', 'Email', '# Of Leads'];
          this.excelService.exportAsExcelFile(filterExportLeads, this.Username + '(' + this.datepipe.transform(new Date(), 'yyyy-MM-dd') + ')' + ' ' + excelfilename, excelHeaders);
          this.toastrService.success('Excel exported', '', this.options);
          this.spinner.hide();
        }
      }
    }, 4000);
    
    /*this.clearSearch();*/
  }
  setOrder(property) {
    let direction = 0;
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    direction = this.isDesc ? 1 : -1;

    this.exportUsers.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  onError(res: any) {
    this.spinner.hide();
    console.log(res);
  }
}
