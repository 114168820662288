import { Component, OnInit, HostListener, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponentService } from 'src/app/services/appcomponenet.service';
import {MDBModalRef, MdbTableDirective, ToastService } from 'ng-uikit-pro-standard';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { NewEvent } from '../Interface/new-event';
import { HeaderService } from '../services/header.service';
import { DocsUploadDownloadService } from '../services/docsuploaddownload.service';
import { CryptoService } from '../services/crypto.service';
import { GraphService } from '../services/graph.service';
import * as XLSX from 'xlsx';
import { ExcelService } from '../services/excel.service';
import * as _ from 'underscore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { UserIdFirstLastDuplicateService, UserIdFirstLastService, UserIdFirstService } from '../Interface/user';
import { ExecutiveLeadsReportService } from '../services/executiveleads.service';

@Component({
  selector: 'app-exportexceluser',
  templateUrl: './exportexceluser.component.html',
  styleUrls: ['./exportexceluser.component.scss']
})
export class ExportExcelUserComponent implements OnInit {
  Username: any; FullnameValue: any; EmailidValue: any; authorizeUserDetails: any[]; jobno: any; exceltoJson = {};
  ImportUserTableForm: FormGroup;
  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };

  fileval: any = ''; exportUser: any[]; exportUserVal: any[]; duplicateUserVal: any[]; exceldata: any; submitted: boolean; Companydetails: any[];

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  searchText: string = ''; previous: string; isDesc: boolean; column: any; activeBranchContact: boolean;
  progressBarloading: boolean = false; saveExcel: boolean;
  fileToUpload: File = null; base64textString: any; model = new NewEvent();
  excelHeaders: string[] = ["Firstname", "Lastname", "Workemail", "Branchname", "Level", "Company", "Mobilenumber", "ManagerFirstname",
    "ManagerLastname", "Manageremail","Manager2Firstname", "Manager2Lastname", "Manage2email", "Manager3Firstname", "Manager3Lastname", "Manager3email"];
  templateToExcel: string[][] = [this.excelHeaders, []]; activeBullet: boolean;
  BranchmasterDetails: any[];  exportUserDuplicatelist: any[];
  exportUseridUniquelist: any[]; exportManagerUseridUniquelist: any[]; exportManagerUserid2Uniquelist: any[]; exportManagerUserid3Uniquelist: any[];

  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";

  modalOptions: any; modalRef: MDBModalRef; ImportUserDetails: any[]; Usernamedetails: any[]; ImportUserValue: any[];

  UserLeveldetails: any[]; branchnameactive: boolean; enableEditImportUser: any; Branchnamedetails: any[];
  userIdFirstLastService = new UserIdFirstLastService(); userIdFirstService = new UserIdFirstService();
  userIdFirstDuplicateService = new UserIdFirstLastDuplicateService();
  constructor(private headerService: HeaderService, private appComponentService: AppComponentService, private router: Router, private activatedroute: ActivatedRoute,
    public cryptoservice: CryptoService, private executiveLeadsReportService: ExecutiveLeadsReportService, public datepipe: DatePipe,
    private docsUploadDownloadService: DocsUploadDownloadService,
    private graphService: GraphService, private toastrService: ToastService, private excelService: ExcelService, public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getCompanyDetails();
    this.ImportUserTableFormData();
    this.getAuthorizeUserDetails();
  }
  
  getAuthorizeUserDetails() {
    this.appComponentService.getAuthorizationForUser().subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getAuthorizationForUserSuccess(getresult: any[]) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      var Fullname = this.authorizeUserDetails[0]['fullname'];
      var UserId = this.authorizeUserDetails[0]['userid'];
      var BranchName = this.authorizeUserDetails[0]['branchname'];
      var Level = this.authorizeUserDetails[0]['level'];
      var Emailid = this.authorizeUserDetails[0]['email'];
      var Application = this.authorizeUserDetails[0]['application'];
      var Password = this.authorizeUserDetails[0]['password'];
      if (UserId != "") {
        this.Username = UserId;
      }
      if (Fullname != "") {
        this.FullnameValue = Fullname;
      }
      if (Emailid != "") {
        this.EmailidValue = Emailid;
      }

     // this.loadExecutiveEmails();
      this.getBranchmaster();
      this.getUserLevel();
    }
  }
  ImportUserTableFormData() {
    this.ImportUserTableForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      userid: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      branchname: [null, Validators.required],
      level: [null, Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.emailPattern)])],
      mobilenumber: ['', Validators.compose([Validators.minLength(13), Validators.maxLength(15)])],
      company: [null, Validators.required]
    });
  }
  get f() { return this.ImportUserTableForm.controls; }
  getUserLevel() {
    let strusername = this.Username;
    this.excelService.getUserLevel(strusername).subscribe(getresult => { this.getUserLevelSuccess(getresult) },
      res => { this.onError(res) });
  }
  getUserLevelSuccess(getresult: any[]) {
    this.UserLeveldetails = [];
    if (getresult.length > 0) {
      this.UserLeveldetails = getresult;
    }
  }
  getBranchmaster() {
    this.progressBarloading = true;
    let strusername = this.Username;
    this.excelService.getBranchMaster(strusername).subscribe(getresult => { this.getBranchMasterSuccess(getresult) },
      res => { this.onError(res) });
  }
  getBranchMasterSuccess(getresult: any[]) {
    this.progressBarloading = false;
    this.BranchmasterDetails = [];
    if (getresult.length > 0) {
      this.BranchmasterDetails = getresult;
    }
  }
  getCompanyDetails() {
    this.excelService.getCompanyDetails().subscribe(getresult => { this.getCompanyDetailsSuccess(getresult) },
      res => { this.onError(res) });
  }
  getCompanyDetailsSuccess(getresult: any[]) {
    this.progressBarloading = false;
    this.Companydetails = [];
    if (getresult.length > 0) {
      this.Companydetails = getresult;
    }
  }
  getImportUserDetails() {
    this.enableEditImportUser = -1;
    this.activeBullet = !this.activeBullet;
    let strusername = this.Username;
    this.excelService.getImportUser(strusername).subscribe(getresult => { this.getImportUserSuccess(getresult) },
      res => { this.onError(res) });
  }
  getImportUserSuccess(getresult: any[]) {
    debugger
    this.progressBarloading = false;
    this.ImportUserDetails = [];
    if (getresult.length > 0) {
      this.ImportUserDetails = getresult;
    }
    else {
      this.activeBullet = false;
    }
    this.loadImportUserDetails();
  }
  storeExcelData(event: any) {
    this.exceldata = event.target;
  }
  loadExcelData() {
    this.spinner.show();
    if (this.Companydetails.length > 0) {
      this.progressBarloading = true;
      this.exportExcelData(this.exceldata);
    }
  }
  exportExcelData(event: any) {
    this.exportUser = [];
    this.exceltoJson = {};
    let headerJson = {};
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(this.exceldata);
    // if (target.files.length !== 1) {
    //   throw new Error('Cannot use multiple files');
    // }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    console.log("filename", target.files[0].name);
    this.exceltoJson['filename'] = target.files[0].name;
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      for (var i = 0; i < wb.SheetNames.length; ++i) {
        const wsname: string = wb.SheetNames[i];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
        this.exceltoJson[`sheet${i + 1}`] = data;
        const headers = this.getheaderrow(ws);
        headerJson[`header${i + 1}`] = headers;
        //  console.log("json",headers)
      }
      this.exceltoJson['headers'] = headerJson;
      this.exportUser = Object.entries(this.exceltoJson).map(([type, value]) => ({ type, value }));
      console.log(this.exportUser[1].value);
      if (this.exportUser[1].value.length > 0) {
        this.insertExcelUser();
      }
    };
  }

  getheaderrow(sheet) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
      // console.log("cell",cell)
      var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
    }
    return headers;
  }
  downloadExcelFormat() {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, "UserExcelFormat" + ".xlsx");
  }
  insertExcelUser() {
    debugger
    this.exportUserVal = []; let list = []; let finalExportUserlist = []; let formatedlist = [];
    this.submitted = true;
    if (this.fileval == '') {
      return;
    }
    this.exportUserVal = this.exportUser[1].value;
    let strusername = this.Username;
    if (this.exportUserVal.length > 0) {
      this.exportUserVal.forEach(x => {
        let Item = {
          "firstname": x.Firstname ? x.Firstname : '',
          "lastname": x.Lastname ? x.Lastname : '',
          "userid": x.Firstname && x.Lastname ? this.userIdFirstLastService.generateUserId(x.Firstname, x.Lastname) :
            x.Firstname?this.userIdFirstService.generateUserId(x.Firstname):'',
          "branchname": x.Branchname ? x.Branchname : '',
          "level": x.Level ? x.Level : '',
          "email": x.Workemail ? x.Workemail.toLowerCase() : '',
          "mobilenumber": x.Mobilenumber ? x.mobilenumber.replace(/[^0-9]+/g, '') : '',
          "company": x.Company ? x.Company : '',
          "managerfirstname": x.ManagerFirstname ? x.ManagerFirstname : '',
          "managerlastname": x.ManagerLastname ? x.ManagerLastname : '',
          "manageruserid": x.ManagerFirstname && x.ManagerLastname ? this.userIdFirstLastService.generateUserId(x.ManagerFirstname, x.ManagerLastname) :
            x.ManagerFirstname ? this.userIdFirstService.generateUserId(x.ManagerFirstname) : '',
          "manageremail": x.Manageremail ? x.Manageremail.toLowerCase() : '',
          "manager2firstname": x.Manager2Firstname ? x.Manager2Firstname : '',
          "manager2lastname": x.Manager2Lastname ? x.Manager2Lastname : '',
          "manager2userid": x.Manager2Firstname && x.Manager2Lastname ? this.userIdFirstLastService.generateUserId(x.Manager2Firstname, x.Manager2Lastname) :
            x.Manager2Firstname ? this.userIdFirstService.generateUserId(x.Manager2Firstname) : '',
          "manager2email": x.Manage2email ? x.Manage2email.toLowerCase() : '',
          "manager3firstname": x.Manager3Firstname ? x.Manager3Firstname : '',
          "manager3lastname": x.Manager3Lastname ? x.Manager3Lastname : '',
          "manager3userid": x.Manager3Firstname && x.Manager3Lastname ? this.userIdFirstLastService.generateUserId(x.Manager3Firstname, x.Manager3Lastname) :
            x.Manager3Firstname ? this.userIdFirstService.generateUserId(x.Manager3Firstname) : '',
          "manager3email": x.Manager3email ? x.Manager3email.toLowerCase() : '',
          "user": strusername
        };
        list.push(Item);
      });
      console.log(list);
      
      this.exportUseridUniquelist = this.getUniqueListBy(list, 'userid');
     
      if (this.exportUseridUniquelist.length > 0 && this.exportUseridUniquelist.length != list.length) {
        let uniqueCount = 0;
        while (this.exportUseridUniquelist.length != list.length) {
          this.exportUserDuplicatelist = [];
          finalExportUserlist = [];
          uniqueCount += 1;
          this.exportUserDuplicatelist = this.findDuplicates(list, 'userid', uniqueCount);
          finalExportUserlist = this.exportUseridUniquelist.concat(this.exportUserDuplicatelist);
          this.exportUseridUniquelist = this.getUniqueListBy(finalExportUserlist, 'userid');
          formatedlist = finalExportUserlist;
        }
      }
      else {
        formatedlist = list;
      }
      if (formatedlist.length > 0) {
          /*return*/
          this.excelService.insertImportUser(formatedlist).subscribe(getresult => { this.insertImportUserSuccess(getresult) },
            res => { this.onError(res) });
        }
      else {
        this.spinner.hide();
        this.exportUseridUniquelist = [];
        this.exportUserDuplicatelist = [];
      }
    }
  }
  insertImportUserSuccess(getresult: any[]) {
    this.spinner.hide();
    this.progressBarloading = false;
    this.exportUser = [];
    if (getresult.length > 0) {
      this.exportUser = getresult;
      let importusermsg = this.exportUser[0].importusermsg;
      if (importusermsg == 'exist') {
        this.toastrService.warning('UserId already exists!', '', this.options);
      }
      else {
        this.toastrService.success('Import of User & Manager data is completed.', '', this.options);
      }
    }
    this.clearData();
  }
  updateImportUserTable(item, e, i) {
    this.spinner.show();
    this.progressBarloading = true;
    this.ImportUserValue = []; let branchname = ''; let user = '';
    this.submitted = true;
    if (this.ImportUserTableForm.invalid) {
      return;
    }
    console.log(this.ImportUserTableForm.value);
    this.ImportUserValue.push(this.ImportUserTableForm.value);

    let branchnameval = this.ImportUserTableForm.value.branchname;
    this.Branchnamedetails.forEach((branch) => {
      if (branch.branchcode == branchnameval) {
        branchname = branch.branchcode;
      }
    })

    if (branchname == '') {
      this.Branchnamedetails.forEach((branch) => {
        if (branch.branchname == branchnameval) {
          branchname = branch.branchcode;
        }
      })
    }
    let userval = this.ImportUserTableForm.value.userid;

    let strusername = this.Username;
    let list = [];
    this.ImportUserValue.forEach(x => {
      let Item = {
        "rowid": item.rowid ? item.rowid.toString():'0',
        "firstname": x.firstname,
        "lastname": x.lastname,
        "userid": x.userid,
        "branchid": branchname,
        "branchname": x.branchname,
        "level": x.level,
        "email": x.email,
        "mobilenumber": x.mobilenumber ? x.mobilenumber.replace(/[^0-9]+/g, '') : '',
        "company": x.company,
        "user": strusername
      };
      list.push(Item);
    });
    this.excelService.updateImportUser(list).subscribe(getresult => { this.updateImportUserSuccess(getresult) },
          res => { this.onError(res) });
  }
  updateImportUserSuccess(getresult: any[]) {
    this.spinner.hide();
    this.ImportUserDetails = [];
    if (getresult.length > 0) {
      this.ImportUserDetails = getresult;
      let importusermsg = this.ImportUserDetails[0].importusermsg;
      if (importusermsg == 'exist') {
        this.toastrService.warning('c', '', this.options);
      }
      else {
        this.toastrService.success('User updated and deleted', '', this.options);
      }
    }
    this.loadImportUserDetails();
    this.submitted = false;
    this.enableEditImportUser = -1;
  }
  deleteImportUserTable(item, e, i) {
    this.spinner.show();
    this.progressBarloading = true;
    let rowid = item.rowid;
    let user = this.Username;
    if (confirm("Are you sure you want to delete this import user?")) {
      this.excelService.deleteImportUser(rowid, user).subscribe(getresult => { this.deleteImportUserSuccess(getresult) },
        res => { this.onError(res) });
      return true;
    }
    else {
      return false;
    }
  }
  deleteImportUserSuccess(getresult: any[]) {
    this.spinner.hide();
    this.ImportUserDetails = [];
    if (getresult.length > 0) {
      this.ImportUserDetails = getresult;
      this.loadImportUserDetails();
    }
    this.toastrService.error('Import user deleted', '', this.options);
    this.enableEditImportUser = -1;
  }
  loadImportUserDetails() {
    this.spinner.hide();
    this.progressBarloading = false;
    this.searchText = '';
    if (this.ImportUserDetails.length > 0) {
      this.mdbTable.setDataSource(this.ImportUserDetails);

      this.ImportUserDetails = this.mdbTable.getDataSource();
      this.previous = this.mdbTable.getDataSource();
    }
  }
  cancelImportUserTable(e, i) {
    this.spinner.hide();
    this.enableEditImportUser = -1;
  }
  getBranchname(evt, flag) {
    let company = evt;
    this.excelService.getCompanyWithBranchDetails(company).subscribe(getresult => { this.getCompanyWithBranchDetailsSuccess(getresult, flag) },
      res => { this.onError(res) });
  }
  getCompanyWithBranchDetailsSuccess(getresult: any[], flag) {
    this.progressBarloading = false;
    this.Branchnamedetails = [];
    if (getresult.length > 0) {
      this.Branchnamedetails = getresult;
    }
  }
  editImportUserTable(item, e, i) {
    debugger
    this.enableEditImportUser = i;
    //if (this.Branchnamedetails.length > 0) {
    //  this.ImportUserDetails.forEach((x) => {
    //    this.Branchnamedetails.forEach((y) => {
    //      if (x.branchid == y.branchcode) {
    //        x.branchname = y.branchname;
    //      }
    //    })
    //  });
    //}
    if (item.company && item.branchname == '') {
      this.companyChange(item.company);
    }
    this.ImportUserTableForm.patchValue({
      firstname: item.firstname, lastname: item.lastname, userid: item.userid, level: item.level, branchname: item.branchname, email: item.email, company: item.company == '' ? null : item.company
    });
  }
  companyChange(evt) {
    if (evt) {
      this.progressBarloading = true;
      this.branchnameactive = true;
      this.ImportUserTableForm.patchValue({
        branchname: null
      });
      this.getBranchname(evt, '0');
    }
    else {
      this.branchnameactive = false;
    }
  }
  branchNameChange() {
    let branchname = '';
    let branchnameval = this.ImportUserTableForm.value.branchname;
    this.Branchnamedetails.forEach((branch) => {
      if (branch.branchcode == branchnameval) {
        branchname = branch.branchname;
      }
    })
    //if (branchname) {
    //  this.getUserMultipleLevel();
    //}
  }
  clearData() {
    this.progressBarloading = false;
    this.submitted = false;
    this.fileval = '';
  }
  getUniqueListBy(arr, key) {
    debugger
    //return [...new Map(arr.map(item => [item[key], item])).values()]
    const seen = new Set<string>();

    return arr.filter(item => {
      const keyValue = item[key];
      if (seen.has(keyValue)) {
        return false;
      } else {
        seen.add(keyValue);
        return true;
      }
    });
  }
  findDuplicates(arr, key, uniqueCount) {
    debugger
    this.duplicateUserVal = []; let list = [];
    //this.duplicateUserVal = _.difference(arr, _.uniq(arr, 'userid'), 'userid');


    const seen = new Set<string>();
    const duplicates = new Set<any>();

    arr.forEach(item => {
      const keyValue = item[key];
      if (seen.has(keyValue)) {
        duplicates.add(item);
      } else {
        seen.add(keyValue);
      }
    });

    this.duplicateUserVal = Array.from(duplicates);
    if (this.duplicateUserVal.length > 0) {
      this.duplicateUserVal.forEach(x => {
      let Item = {
        "firstname": x.firstname ? x.firstname : '',
        "lastname": x.lastname ? x.lastname : '',
        "userid": this.generateUserId(x, key, uniqueCount),
        "branchname": x.branchname ? x.branchname : '',
        "level": x.level ? x.level : '',
        "email": x.email ? x.email.toLowerCase() : '',
        "mobilenumber": x.mobilenumber ? x.mobilenumber.replace(/[^0-9]+/g, '') : '',
        "company": x.company ? x.company : '',
        "managerfirstname": x.managerfirstname ? x.managerfirstname : '',
        "managerlastname": x.managerlastname ? x.managerlastname : '',
        "manageruserid": this.generateMangerUserId(x, key),
        "manageremail": x.manageremail ? x.manageremail.toLowerCase() : '',
        "manager2firstname": x.manager2firstname ? x.manager2firstname : '',
        "manager2lastname": x.manager2lastname ? x.manager2lastname : '',
        "manager2userid": this.generateManger2UserId(x, key),
        "manager2email": x.manager2email ? x.manager2email.toLowerCase() : '',
        "manager3firstname": x.manager3firstname ? x.manager3firstname : '',
        "manager3lastname": x.manager3lastname ? x.manager3lastname : '',
        "manager3userid": this.generateManger3UserId(x, key),
        "manager3email": x.manager3email ? x.manager3email.toLowerCase() : '',
        "user": x.user
      };
      list.push(Item);
    });
    }
    console.log(this.duplicateUserVal);
    return list;
  }
  generateUserId(x: any, keyval: string, uniqueCount:any): string {
    if (keyval === 'userid') {
      if (x.firstname && x.lastname) {
        return this.userIdFirstDuplicateService.generateUserId(x.firstname, x.lastname, uniqueCount);
      } else if (x.firstname) {
        return this.userIdFirstService.generateUserId(x.firstname);
      } else {
        return '';
      }
    } else {
      return this.userIdFirstLastService.generateUserId(x.firstname, x.lastname);
    }
  }
  generateMangerUserId(x: any, keyval: string): string {
      if (x.managerfirstname && x.managerlastname) {
        return this.userIdFirstLastService.generateUserId(x.managerfirstname, x.managerlastname);
      } else if (x.managerfirstname) {
        return this.userIdFirstService.generateUserId(x.managerfirstname);
      } else {
        return '';
      }
  }
  generateManger2UserId(x: any, keyval: string): string {
      if (x.manager2firstname && x.manager2lastname) {
        return this.userIdFirstLastService.generateUserId(x.manager2firstname, x.manager2lastname);
      } else if (x.manager2firstname) {
        return this.userIdFirstService.generateUserId(x.manager2firstname);
      } else {
        return '';
      }
  }
  generateManger3UserId(x: any, keyval: string): string {
      if (x.manager3firstname && x.manager3lastname) {
        return this.userIdFirstLastService.generateUserId(x.manager3firstname, x.manager3lastname);
      } else if (x.manager3firstname) {
        return this.userIdFirstService.generateUserId(x.manager3firstname);
      } else {
        return '';
      }
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.ImportUserDetails = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.ImportUserDetails = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
  dtTablesortImportUser(property) {
    let direction = 0;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    direction = this.isDesc ? 1 : -1;

    this.ImportUserDetails.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  omitSpacechar(evt: any) {
    //var k;
    //k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var startPos = evt.currentTarget.selectionStart;
    //var startPos = evt.location;
    if (charCode === 32 && startPos == 0) {
      evt.preventDefault();
    }

  }
  isNumberwithchar(evt: any) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (!(charCode > 47 && charCode < 58) && // numeric (0-9)
      !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
      !(charCode > 96 && charCode < 123)) {
      evt.preventDefault();
    }
  }
  isNumberPhonenumber(evt: any) {
    let mobilenumber = '';
    if (mobilenumber != undefined) {
      var phonenumberLengt = mobilenumber.length;
    }
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var cleaned = ('' + mobilenumber).replace(/\D/g, '').length;
    if (charCode > 31 && charCode != 45 && charCode != 40 && charCode != 41 && charCode != 32 && (charCode < 48 || charCode > 57) || phonenumberLengt >= 15 || cleaned >= 11) {
      evt.preventDefault();
    }
  }
  Phonenumberchange(fieldTypedtext) {
    var prefix = ('' + fieldTypedtext).replace(/\D/g, '');
    // this.phonenumberfieldtext = prefix;
    var strUsername = this.Username;
    var phonenumberlength = fieldTypedtext.length;
    if (phonenumberlength > 15) {
      return false;
    }
    let phone = '';
    var cleanedlength = ('' + prefix).replace(/\D/g, '').length;
    if (cleanedlength == 10) {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
        this.ImportUserTableForm.patchValue({
          mobilenumber: phone
        });
      }
    }
    else {
      var cleaned = ('' + prefix).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        phone = match[1] + '(' + match[2] + ') ' + match[3] + '-' + match[4];
        this.ImportUserTableForm.patchValue({
          mobilenumber: phone
        });
      }
    }
  }
  onError(res: any) {
    this.spinner.hide();
    this.clearData();
    this.toastrService.error('Use downloaded template / UserId already exists!', '', this.options);
    console.log(res);
  }
}
