import { Component, OnInit, HostListener, OnDestroy, OnChanges, Input } from '@angular/core';
import { AppComponentService } from 'src/app/services/appcomponenet.service';
import { User } from './Interface/user';
/*import { BroadcastService, MsalService } from '@azure/msal-angular';*/
import { CryptoUtils, InteractionRequiredAuthError, Logger } from 'msal';
import { AuthendicationService } from './services/authendication.service';
import { AppSettings } from './Appsettings';
import { ConnectionService } from 'ng-connection-service';
import { concat, fromEvent, Observable, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationdilogService } from './services/confirmationdilog.service';
import { Router } from '@angular/router';
import { MDBModalRef, MDBModalService, ToastService } from 'ng-uikit-pro-standard';
import { AuthGuardService } from './services/auth-guard.service';
import { UserAddPopupComponent } from './shared/useraddpopup/useraddpopup.component';
import { DefaultLeadAddService } from './services/defaultleadadd.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnChanges, OnDestroy {
  GRAPH_ENDPOINT = AppSettings.OAuthSettings.GRAPH_ENDPOINT;
  //accessTokenRequest = {
  //  scopes: [AppSettings.OAuthSettings.api]
  //};
  accessTokenRequest = {
    scopes: ['user.read', 'openid', 'profile', 'offline_access']
  };
  data: boolean; gifload: boolean; authorizeUserDetails: any[]; Defaultimage: any = "assets/Pavion.png";
  DefaultBackGroundimage: any = { 'background-image': 'url(../assets/Pavion.png)' };
  mainloginImage = "assets/mainlogin.png"; defaultloginImage = "assets/defaultlogin.png";
  isIframe: boolean; subscription: any; clientEmail: any; profile: any; loggedIn: boolean;
  userIdVal: boolean; switchUserDetails: any[]; ProfileEmailid: any; switchuserLength: any;
   connectionstatus = 'online'; isConnected = true; profilename: any;
  idleTime: any; idleTime$!: Observable<number>;

  options = { enableHtml: false, timeOut: 3000, positionClass: 'md-toast-top-center' };
  headerlogo: any; company: any; blackpanellogo: any; favIcon: any;
  modalOptionUserAdd: any; modalRef: MDBModalRef; UserAddPopup: any[]; logedInUser: any;
  loginBtn: boolean = false; @Input() guestuserFlag: any; guestuserFlagValue: any = '1';
  constructor(private appComponentService: AppComponentService, private auth: AuthendicationService/*, private broadcastService: BroadcastService*/
    /*, private authService: MsalService*/, private connectionService: ConnectionService, private modalService: MDBModalService,
    private confirmationdilogservice: ConfirmationdilogService, private router: Router, private toastrService: ToastService,
    private authGuardService: AuthGuardService, private defaultLeadService: DefaultLeadAddService) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.connectionstatus = "Online";
        this.toastrService.success(this.connectionstatus, '', this.options);

        //this.toastr.success(this.connectionstatus, null, { 
        //  timeOut: 3000,
        //  positionClass: 'toast-top-center',
        //})
      }
      else {
        this.connectionstatus = "Offline";
        this.toastrService.error(this.connectionstatus, '', this.options);

        //this.toastr.error(this.connectionstatus, null, {
        //  timeOut: 3000,
        //  positionClass: 'toast-top-center',
        //})
      }
    })
  }
  ngOnChanges() {
    debugger
    if (this.guestuserFlag == '1') {
      this.guestuserFlagValue = '1';
    }
    else {
      this.guestuserFlagValue = '2';
    }
  }
  ngOnInit() {
    debugger
    this.favIcon = document.getElementById('appIcon');
    this.profilename = 'Select Profile';

        /*this.checkoutAccount();*/
    this.defaultLeadService.bsRoot.subscribe(x => {
      debugger
      if (x == '2') {
        this.gifload = true;
        this.loginBtn = false;
        this.checkoutAccount()
      }
      else {
      this.loginBtn = true;
        this.router.navigate(['defaultleadadd'], { skipLocationChange: false });
      }

      //if (x == '2') {
      //  this.gifload = true;
      //  this.loginBtn = false;
      //  this.defaultlogin = false;
      //  this.checkoutAccount()
      //}
      //else {
      //  this.loginBtn = true;
      //  this.defaultlogin = false;
      //}
    });
    /*this.router.navigate(['guestuseradd'], { skipLocationChange: false });*/

    //this.login();
    //this.getToken();
    //this.isIframe = window !== window.parent && !window.opener;


    //this.broadcastService.subscribe('msal:loginSuccess', () => {
    //  this.checkoutAccount();
    //});

    //this.authService.handleRedirectCallback((authError, response) => {
    //  if (authError) {
    //    console.error('Redirect Error: ', authError.errorMessage);
    //    return;
    //  }

    //  console.log('Redirect Success: ', response);
    //});

    //this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
    //  console.log('MSAL Logging: ', message);
    //}, {
    //  correlationId: CryptoUtils.createNewGuid(),
    //  piiLoggingEnabled: false
    //}));
    /*this.getAuthorizeUserDetails();*/
  }
  getAuthorizeUserDetails(email) {
    debugger
    this.appComponentService.getAuthorizationForUserMail(email).subscribe(getresult => { this.getAuthorizationForUserSuccess(getresult); }, res => { this.onError(res); });
  }
  getAuthorizationForUserSuccess(getresult) {
    debugger
    this.switchUserDetails = [];
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      if (getresult.length > 1) {
        this.switchUserDetails = getresult;
        this.switchuserLength = getresult.length;

        this.loadUserImage(getresult);
        this.userIdVal = true;
        this.data = false;
        this.gifload = false;
        this.loginBtn = false;
      }
      else {
        this.userIdVal = false;
        this.authorizeUserDetails = getresult;
        this.loadUserImage(getresult);
        this.authGuardService.getUserLevel(this.authorizeUserDetails);
        this.data = true;
        this.gifload = false;
        this.userIdVal = false;
        this.loginBtn = false;

        //if (this.Active == true) {
        //  this.authGuardService.getUserLevel(this.authorizeUserDetails);
        //  this.data = true;
        //  this.gifload = false;
        //  this.userIdVal = false;
        //  this.Application = false;
        //}
        //else {
        //  this.data = false;
        //  this.gifload = false;
        //  this.userIdVal = false;
        //  this.Application = false;
        //}
      }
    }
    else {
      this.openUserAddPopupModal();
      this.data = false;
      this.gifload = false;
      this.userIdVal = false;
      this.loginBtn = false;
    }
  }

  loadUserImage(getresult) {
    let company = ''; let headerlogo = ''; let blackpanellogo = '';
    if (getresult.length > 0) {
      let company = getresult[0].company;
      if (getresult.length > 1) {
        if (company) {
          this.company = 'PAVION CONNECT AND PROTECT';
          headerlogo = 'PavionLogo.png';
          blackpanellogo = 'Pavion-logo.jpg';
          if (this.company && blackpanellogo) {
            this.favIcon.setAttribute("href", '../assets/' + blackpanellogo);
          }
          if (headerlogo) {
            this.headerlogo = '../assets/' + headerlogo;
          }
          if (blackpanellogo) {
            this.blackpanellogo = { 'background-image': 'url(../assets/' + blackpanellogo + ')' };
          }
        }
      }
      else {
        if (company) {
          this.company = company.toLowerCase();
          headerlogo = getresult[0].headerlogo;
          blackpanellogo = getresult[0].blackpanellogo;
          if (this.company && blackpanellogo) {
            this.favIcon.setAttribute("href", '../assets/' + blackpanellogo);
          }
          if (headerlogo) {
            this.headerlogo = '../assets/' + headerlogo;
          }
          if (blackpanellogo) {
            this.blackpanellogo = { 'background-image': 'url(../assets/' + blackpanellogo + ')' };
          }
        }
      }

    }
  }
  //copy file
  checkoutAccount() {
    debugger
   // this.loggedIn = !!this.authService.getAccount();
    //if (this.loggedIn) {
    this.ProfileEmailid = null;
    this.appComponentService.getLogedinUser().subscribe(getresult => { this.getLogedinUserSuccess(getresult)}
      , res => { this.onError(res); });
   
      //console.log(emailid);
      //console.log(this.authService.getAccount().name);
      //console.log(this.authService.getAccount().userName);
      //console.log(this.authService.getAccount().accountIdentifier);
      //if (this.profile != '' && this.profile != undefined) {
      //  this.clientEmail = this.profile.mail;
      //}
      //else {
      // // this.ProfileEmailid = this.authService.getAccount().userName;
      //}
    //  if (this.clientEmail != '' && this.clientEmail != undefined) {
    //    this.getAuthorizeUserDetails(this.clientEmail);
    //  }
    //  else {
    //    let ProfileEmailid = this.ProfileEmailid;
    //    this.getAuthorizeUserDetails(ProfileEmailid);
    //}
    //}
    //else {
    //  this.authService.loginPopup();
    //}
  }
  getLogedinUserSuccess(getresult) {
    debugger
    if (getresult) {
      if (getresult != '' && getresult != undefined) {
        this.logedInUser = getresult;
        this.getAuthorizeUserDetails(getresult);
      }
      else {
        this.gifload = false;
        this.toastrService.error('Authorization failed,Please contact administrator!', '', this.options);
      }
    }
  }
  //login() {
  //  const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  //  if (isIE) {
  //    this.authService.loginRedirect();
  //    //this.authService.loginRedirect(this.accessTokenRequest);
  //  }
  //  else {
  //    this.authService.loginPopup();
  //    //this.authService.loginPopup(this.accessTokenRequest);
  //  }
  //}
  //getProfile() {
  //  this.http.get(this.GRAPH_ENDPOINT)
  //    .toPromise().then(profile => {
  //      this.profile = profile;
  //      console.log(this.profile);
  //    });
  //}
  //getToken() {
  //  this.authService.acquireTokenPopup(this.accessTokenRequest).then(function (tokenResponse) {
  //    // Callback code here
  //    //console.log(tokenResponse);
  //    //console.log(tokenResponse.accessToken);
  //  }).catch(function (error) {
  //    console.log(error);
  //  });
  //  this.subscription = this.broadcastService.subscribe("msal:acquireTokenSuccess", (payload) => {
  //    //console.log("acquire token success " + JSON.stringify(payload));
  //    //JSON.stringify(payload);
  //  });
  //  ////will work for acquireTokenSilent and acquireTokenPopup
  //  //this.subscription = this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
  //  //  //console.log("acquire token failure " + JSON.stringify(payload))
  //  //  if (payload.errorDesc.indexOf("consent_required") !== -1 || payload.errorDesc.indexOf("interaction_required") != -1) {
  //  //    this.authService.acquireTokenPopup(this.accessTokenRequest).then((token) => {
  //  //      //this.getProfile();
  //  //    }, (error) => {
  //  //    });
  //  //  }
  //  //});
  //  //this.getTokenSilent();
  //}
  //getTokenSilent() {
  //  this.authService.acquireTokenSilent(this.accessTokenRequest).then(function (tokenResponse) {
  //    // Callback code here
  //    //console.log(tokenResponse);
  //    //console.log(tokenResponse.accessToken);
  //  }).catch(function (error) {
  //    console.log(error);
  //  });
  //  this.subscription = this.broadcastService.subscribe("msal:acquireTokenSuccess", (payload) => {
  //    //console.log("acquire token success " + JSON.stringify(payload));
  //    //JSON.stringify(payload);
  //  });
  //}
  onUserIdChangeSelect(val) {
    debugger
    let switchuser = val;
    let ProfileEmailid = this.ProfileEmailid;
    this.appComponentService.getSwitchForUser(switchuser, ProfileEmailid).subscribe(getresult => { this.getSwitchForUserSuccess(getresult); }, res => { this.onError(res); });
  }
  getSwitchForUserSuccess(getresult) {
    this.authorizeUserDetails = [];
    if (getresult.length > 0) {
      this.authorizeUserDetails = getresult;
      this.loadUserImage(getresult);

        this.authGuardService.getUserLevel(this.authorizeUserDetails);
        this.data = true;
        this.gifload = false;
      this.userIdVal = false;
      this.loginBtn = false;

      //if (Application == 'crm' || Application == 'both') {
      //  this.authGuardService.getUserLevel(this.authorizeUserDetails);
      //  this.data = true;
      //  this.gifload = false;
      //  this.userIdVal = false;
      //  this.Application = false;
      //}
      //else {
      //  this.data = false;
      //  this.gifload = false;
      //  this.userIdVal = false;
      //  this.Application = false;
      //}
    }
  }
  //extremely important to unsubscribe
  ngOnDestroy() {
    //this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    localStorage.clear();
    return false;
  }

  //@HostListener('window:beforeunload', ['$event'])
  //beforeUnloadHander(event) {
  //  localStorage.clear();
  //  return false;
  //}

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:wheel')
  @HostListener('document:resize')
  @HostListener('document:touchstart')
  @HostListener('document:touchend')
  resetidleTimer() {
    let time = 0;
    clearTimeout(this.idleTime);
    this.idleTime = setTimeout(() => {
      const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
      const keypress$ = fromEvent<MouseEvent>(document, 'keypress');
      const click$ = fromEvent<MouseEvent>(document, 'click');
      const wheel$ = fromEvent<MouseEvent>(document, 'wheel');
      const resize$ = fromEvent<MouseEvent>(document, 'resize');
      const touchstart$ = fromEvent<MouseEvent>(document, 'touchstart');
      const touchend$ = fromEvent<MouseEvent>(document, 'touchend');

      const combine$ = concat(mouseMove$, keypress$, click$, wheel$, resize$, touchstart$, touchend$);
      this.idleTime$ = timer(0, 1000);
      this.idleTime$.pipe(takeUntil(combine$)).subscribe(x => {
        time = x;
        if (time == 0) {
          this.openConfirmationDialog();
        }
        //else if (time == AppSettings.OAuthSettings.tokenExpireTime) {
        //  this.getTokenSilent();
        //}
      });



    }, AppSettings.OAuthSettings.idleTime);
  }





  logout() {
    localStorage.clear();
    //this.authService.logout();
    this.signOut();
  }
  openConfirmationDialog() {
    //this.confirmationdilogservice.confirm('Your session has been idle.', 'Do you want to continue?')
    //  .then((confirmed) => {
    //    if (confirmed == true) {
    //      this.getTokenSilent();
    //    }
    //    else {
    //      this.logout();
    //    }
    //  })
    //  .catch(res => { this.onError(res) });

    localStorage.clear();
    let date = new Date();
    let estdatetimenow = date.toLocaleString('en-US', { timeZone: 'America/New_York' });
    let estdatenow = estdatetimenow.split(',')[0].trim();
    let esttimenow = estdatetimenow.split(',')[1].trim();
    let estofficetime = "04:30 AM";
    let estofficedate = (date.getMonth() + 1) + "/" + (date.getDate() + 1) + "/" + date.getFullYear() + ',' + ' ' + estofficetime;
    let getdate = new Date(estofficedate);
    let estofcclosedate = getdate.toLocaleString('en-US', { timeZone: 'America/New_York' });

    if (estdatetimenow >= estofcclosedate) {
      this.logout();
    }
    else {
      this.logout();
    }
  }
  ModalOptionUserAddPopupLoad() {
    this.modalOptionUserAdd = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-fluid modal-dialog-centered',
      containerClass: '',
      animated: true,
      data: {
        userAddPopupmodaldata: {
          userId: this.logedInUser
        }
      }
    }
  }
  openUserAddPopupModal() {
    debugger
    this.ModalOptionUserAddPopupLoad();
    this.UserAddPopupModal();
  }
  UserAddPopupModal() {
    this.modalRef = this.modalService.show(UserAddPopupComponent, this.modalOptionUserAdd);
    this.modalRef.content.action.subscribe((result: any) => {
      debugger
      this.UserAddPopup = [];
      this.UserAddPopup = result;
      if (result) {
        this.getAuthorizeUserDetails(result);
      }
    });
  }
  //DefaultLogin() {
  //  this.loginBtn = false;
  //  this.defaultlogin = true;
  //  this.router.navigate(['defaultleadadd'], { skipLocationChange: false });
  //}
  //MainLogin() {
  //  this.gifload = true;
  //  this.loginBtn = false;
  //  this.defaultlogin = false;
  //  this.checkoutAccount()
  //}

  signOut() {
    // this.authService.logout();
    this.clearCookies(); // Clear cookies to ensure no session remains
  }
  clearCookies(): void {
    debugger
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
    /*location.reload();*/
    this.loadLogOutUser();
  }
  loadLogOutUser() {
    this.appComponentService.getLogOutUser().subscribe({
      next: (response) => {
        debugger
        console.log("Logout successful", response);

        localStorage.clear();
        sessionStorage.clear();

        //// Redirect to the Windows-authenticated page and reload
        //setTimeout(() => {
        //  window.location.href = '/createlead';
        //  window.location.reload(true); // Force reload without cache
        //}, 100);
      },
      error: (err) => { this.onError(err) }
    });
    // Redirect to the Windows-authenticated page and reload
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/createlead';
      window.location.reload(true); // Force reload without cache
    }, 1000);
  }
  onError(res: any) {
    console.log(res);
    this.gifload = false;
    this.toastrService.error('Authorization failed,Please contact administrator!', '', this.options);
  }
}
